import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const Services = props => {
  const services = props.data.services.edges;
  const serviceItems = props.data.serviceItems.edges;
  const { intro } = props.data;
  const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;

  return (
    <Layout bodyClass="page-services">
      <SEO title="Services" />

      <div className="intro">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img loading="lazy" width="3" height="2" alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="strip strip-grey">
        <div className="container pt-4 pb-4 pb-md-6 text-center">
          <h1 className="mb-2 underline">We specialize in:</h1>
          {serviceItems.slice(1).map(({ node }, index) => (
            <div className="mb-2 pt-2">
              <h2 className="mb-2">{node.title}</h2>
              <ul key={node.id} className="services-row triple-cols row justify-content-center align-items-center">
                {node.types.map(({ name }) => (
                  <li className="col-12 col-md-4 mb-1">
                    <div className="card service service-teaser">
                      <div className="card-content">
                        <h2 className="service-items font-weight-light">{name}</h2>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {/* {index !== serviceItems.length - 2 && (<hr className="mb-4 mt-4"></hr>)} */}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    serviceItems: allServicesJson {
      edges {
        node {
          id
          title
          types {
            name
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services\/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: {regex: "/(services.md)/"}) {
      html
      frontmatter {
        title
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
      }
    }
  }
`;

export default Services;
